import { requestSubscription } from 'react-relay'
import type { Context as DataDogContext } from '@datadog/browser-core'
import relayService from 'shared/utils/relay'
import type { Disposable } from 'relay-runtime'

import { graphql } from 'relay-hooks'

import logging from 'shared/utils/logging'
import datadog from 'shared/utils/logging/integrations/datadog'

import type {
  loggingInitializerSubscription,
  loggingInitializerSubscription$data,
} from './__generated__/loggingInitializerSubscription.graphql'

type User = Partial<loggingInitializerSubscription$data['userUpdated']>

let disposable: Disposable | null

const subscription = graphql`
  subscription loggingInitializerSubscription($id: ID!) {
    userUpdated(id: $id) {
      id
      company {
        id
        name
        type
      }
    }
  }
`

const mergeData = (user: User) => {
  datadog.mergeProps({
    user,
  })
}

const handleError = (error: Error) => {
  logging.logError('Datadog user subscription error', error)
}

const handleResponse = (response?: loggingInitializerSubscription$data | null) => {
  if (!response) {
    return
  }
  mergeData(response.userUpdated)
}

const handleCompleted = () => {
  logging.logInfo('Datadog user subscription closed by server', {})
}

const initialize = (user: User) => {
  try {
    mergeData(user)

    if (user.id) {
      disposable = requestSubscription<loggingInitializerSubscription>(relayService.environment, {
        subscription,
        variables: {
          id: user.id,
        },
        onError: handleError,
        onNext: handleResponse,
        onCompleted: handleCompleted,
      })
    }
  } catch (error) {
    logging.logInfo('Datadog user fetch error', error as DataDogContext)
  }
}

const dispose = () => {
  if (disposable) {
    disposable.dispose()
    disposable = null
    logging.logInfo('Datadog user subscription disposed', {})
  }
}

export default { initialize, dispose }
