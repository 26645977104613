import { useEffect } from 'react'

import datadog from 'shared/utils/logging/integrations/datadog'
import useCurrentUser from 'web-client/hooks/useCurrentUser'

import Storage from 'web-client/utils/storage'
import workerTimers from 'web-client/utils/workerTimers'
import useSessionStorage from 'react-use/lib/useSessionStorage'
import throwError from 'web-client/utils/throwError'

export const CHECKIN_TIME = 1000 * 60 * 5
export const EXPIRE_TIME = CHECKIN_TIME * 2
export const TAB_SESSION_TIMES = 'tabSessionTimes'
export const TAB_ID = 'tabId'

const getSessions: () => Record<string, number> = () => {
  try {
    const sessions = JSON.parse(Storage.get(TAB_SESSION_TIMES) || '{}')
    return sessions
  } catch (e) {
    return {}
  }
}

const setSessions = (sessions: Record<string, number>) => {
  Storage.set(TAB_SESSION_TIMES, JSON.stringify(sessions))
}

let hookUsed = false
const useTabCounter = () => {
  const { isLoggedIn } = useCurrentUser()
  const [tabCounterId] = useSessionStorage<string>(TAB_ID, `${Date.now()}`)

  useEffect(() => {
    if (hookUsed) {
      throwError(new Error('useTabCounter should only be used once at the root of the application'))
    }
    hookUsed = true
    return () => {
      hookUsed = false
    }
  }, [])

  useEffect(() => {
    // Add myself as a tab
    if (isLoggedIn) {
      const sessions = getSessions()
      sessions[tabCounterId] = Date.now()
      setSessions(sessions)
    }
  }, [isLoggedIn, tabCounterId])

  // Remove if the user closes the tab
  useEffect(() => {
    const cleanup = () => {
      const sessions = getSessions()
      delete sessions[tabCounterId]
      setSessions(sessions)
    }

    window.addEventListener('beforeunload', cleanup)

    return () => {
      window.removeEventListener('beforeunload', cleanup)
    }
  }, [tabCounterId])

  useEffect(() => {
    if (isLoggedIn) {
      const interval = workerTimers.setInterval(() => {
        const sessions = getSessions()
        // Let others know I'm still alive
        sessions[tabCounterId] = Date.now()

        // Remove any tabs that haven't update themselves recently
        const currentTime = Date.now()
        const keys = Object.keys(sessions)
        keys.forEach((key: string) => {
          if (currentTime - sessions[key] > EXPIRE_TIME) {
            delete sessions[key]
          }
        })

        // If I'm the first key in the list, send a log to datadog with total count
        const [first] = keys
        if (first === tabCounterId) {
          datadog.logInfo('Tab Count', {
            tab_count: keys.length,
            tabs: keys,
            sessions,
          })
        }

        setSessions(sessions)
      }, CHECKIN_TIME)

      return () => {
        workerTimers.clearInterval(interval)
      }
    }
  }, [isLoggedIn, tabCounterId])
}

export default useTabCounter
