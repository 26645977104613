import { useCallback, useState } from 'react'

// Usage
// @link: https://github.com/joinswoop/swoop/wiki/componentLibrary-Hooks#usererender
export function useRerender() {
  // eslint-disable-next-line react/hook-use-state
  const [, update] = useState(0)
  return useCallback(() => {
    update((currentCount) => currentCount + 1)
  }, [])
}
