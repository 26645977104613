import { useMemo } from 'react'

import { graphql, useQuery } from 'relay-hooks'

import type { Scalars } from 'graphql/enums'
import type Descriptions from 'graphql/descriptions.json'

import useCurrentUser from './useCurrentUser'

import type { useFeaturesEnabledQuery } from './__generated__/useFeaturesEnabledQuery.graphql'

const useFeaturesEnabled = (options?: { companyId?: Scalars['ID'] }) => {
  const { currentUser } = useCurrentUser()

  const companyId = options?.companyId || currentUser.company.id

  const { data, error, isLoading } = useQuery<useFeaturesEnabledQuery>(
    graphql`
      query useFeaturesEnabledQuery($companyId: ID!) {
        node(id: $companyId) {
          ... on Company {
            features(first: 300) {
              nodes {
                key
              }
            }
          }
        }
      }
    `,
    { companyId },
    { skip: !companyId }
  )

  const featuresEnabled = useMemo(
    () =>
      data?.node?.features?.nodes.reduce<
        Partial<Record<keyof typeof Descriptions.FeatureEnum, boolean>>
      >(
        (record, { key }) => ({
          ...record,
          [key]: true,
        }),
        {}
      ) || {},
    [data?.node?.features?.nodes]
  )

  return {
    error,
    featuresEnabled,
    isLoading,
  }
}

export default useFeaturesEnabled
