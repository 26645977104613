import logging from 'shared/utils/logging'

const enforceSiteUrl = () => {
  if (
    window.location.origin === window.ENV.SITE_URL ||
    window.ENV.ALTERNATIVE_SITE_URLS.split(',').includes(window.location.origin)
  ) {
    return
  }

  logging.logWarning('App accessed from invalid origin', { origin: window.location.origin })
  window.location.href = `${window.ENV.SITE_URL}${window.location.pathname}${window.location.search}`
}

export default enforceSiteUrl
