import { useEffect, useState } from 'react'
import moment from 'moment-timezone'

// Usage
// const secondsRemaining = useCountdownSeconds('Sat Jan 18 2020 18:08:50 GMT-0800')
export const useCountdownSeconds = (expiresAt) => {
  const timeDiff = (expires) => {
    if (!moment(expires).isValid()) {
      return -1
    }
    return moment(expires) - moment()
  }
  const [timeRemaining, setTimeRemaining] = useState(timeDiff(expiresAt))

  useEffect(() => setTimeRemaining(timeDiff(expiresAt)), [expiresAt])

  useEffect(() => {
    let pendingTimeout
    if (timeRemaining > -1) {
      pendingTimeout = setTimeout(() => {
        setTimeRemaining(timeDiff(expiresAt))
      }, 1000)
    }
    return () => clearTimeout(pendingTimeout)
  }, [expiresAt, timeRemaining])

  if (!expiresAt) {
    return -1
  }

  const roundedTime = Math.ceil(timeRemaining / 1000)

  // Returns -1 when time is expired
  if (roundedTime <= -1) {
    return -1
  }

  // Returns time remaining when time has not yet expired
  return roundedTime
}
