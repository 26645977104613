import useDatadogMemoryLogger from 'web-client/hooks/useDatadogMemoryLogger'
import useTabCounter from './useTabCounter'
import useRefreshTracker from './useRefreshTracker'

const usePerformanceMetrics = () => {
  useRefreshTracker()
  useTabCounter()
  useDatadogMemoryLogger()
  return null
}

export default usePerformanceMetrics
