import React, { useEffect } from 'react'

import { useModal, Button } from 'design-system/components/Modals'
import StandardModal, {
  Header,
  Main,
  FlexFooter,
} from 'design-system/components/Modals/StandardModal'
import { useCountdownSeconds } from 'web-client/hooks/useCountdownSeconds'
import logging from 'shared/utils/logging'

type Props = Readonly<{
  id: string
  onContinue: () => void
  onLogout: () => void
  expiresAt: moment.Moment | Date
  lastClickTime: number
}>

const LogoutWarning = ({ id, onContinue, onLogout, expiresAt, lastClickTime, ...rest }: Props) => {
  const { closeModal } = useModal()
  const secondsRemaining = useCountdownSeconds(expiresAt)

  const handleLogout = () => {
    closeModal(id)
    logging.logInfo('Pressed logout on session timeout modal', {})
    onLogout()
  }

  const handleContinue = () => {
    closeModal(id)
    onContinue()
  }

  useEffect(() => {
    if (secondsRemaining === 0) {
      logging.logInfo('Session timeout automatically', {
        inactivity_time_in_minutes: (Date.now() - lastClickTime) / (1000 * 60),
      })
      closeModal(id)
      onLogout()
    }
  }, [secondsRemaining, lastClickTime, onLogout, closeModal, id])

  const content = `Your session is about to expire due to inactivity. You will be logged out in ${secondsRemaining} second${
    secondsRemaining === 1 ? '' : 's'
  }.`

  return (
    <StandardModal
      closeButton={false}
      closeOnOutsideClick={false}
      data-testid="logout-warning-modal"
      id={id}
      {...rest}
    >
      <Header>Session Timeout</Header>
      <Main>{content}</Main>
      <FlexFooter>
        <Button onClick={handleLogout} secondary size="s" type="button">
          Log out now
        </Button>
        <Button onClick={handleContinue} size="s" type="button">
          Keep me signed in
        </Button>
      </FlexFooter>
    </StandardModal>
  )
}

export default LogoutWarning
