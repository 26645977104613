import auth0Service from 'web-client/utils/auth0'
import type { Router, Middleware } from 'router5'
import logging from 'shared/utils/logging'
import clearStorage from './clearStorage'

async function beforeRenderRedirect(router: Router, done: Parameters<Middleware>['2']) {
  try {
    const isAuthenticated = await auth0Service.isAuthenticated()

    if (isAuthenticated) {
      logging.logInfo('Vanity url is authenticated redirecting to default', { feature: 'SSO' })
      router.navigateToDefault()
      return
    }

    logging.logInfo('Trying to refresh auth0 token on vanity url', { feature: 'SSO' })
    await auth0Service.refresh()
    logging.logInfo('Successfully refreshed auth0 token on vanity url', { feature: 'SSO' })

    router.navigateToDefault()
  } catch (error) {
    logging.logInfo('Failed to refresh auth0 token on vanity url', { feature: 'SSO' })
    clearStorage('SSO')
    done()
  }
}

export default beforeRenderRedirect
