import React from 'react'

import styled from '@emotion/styled'
import colors from 'design-system/tokens/colors'
import ErrorBoundary from 'shared/components/ErrorBoundary'
import BaseCompanyLogo from './CompanyLogo'

const HeaderComponent = styled.header`
  background-color: ${colors.white};
  display: flex;
  z-index: 1030;
  box-shadow:
    0 0 2px 0 rgba(141, 141, 148, 0.16),
    0 4px 8px -2px rgba(141, 141, 148, 0.28);
`

export const headerHeight = 60

const LogoContainer = styled.div`
  display: flex;
  flex: 0 0 auto;
  font-size: 0;
  height: ${headerHeight}px;
  line-height: ${headerHeight}px;
  justify-content: center;
`

const CompanyLogo = styled(BaseCompanyLogo)`
  align-items: center;
  display: inline-flex;
  padding: 0 28px;
`

type HeaderProps = {
  useSwoopLogo?: boolean
  children?: React.ReactNode
}

const LogoOnlyHeader = ({ useSwoopLogo = false, children }: HeaderProps) => {
  return (
    <HeaderComponent data-testid="swoop-header">
      <ErrorBoundary>
        <LogoContainer data-testid="swoop-logo">
          <CompanyLogo useSwoopLogo={useSwoopLogo} />
        </LogoContainer>
        {children}
      </ErrorBoundary>
    </HeaderComponent>
  )
}

export default LogoOnlyHeader
