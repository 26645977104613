import { useEffect } from 'react'
import EventRegistry from 'web-client/utils/EventRegistry'
import { useInstance } from 'web-client/hooks/useInstance'
import { useRerender } from 'web-client/hooks/useRerender'

export type StoreProps = {
  bind: (eventType: string, callback?: () => void) => void
  unbind: (eventType: string, callback?: () => void) => void
}

let BC_ID_ITTR = 1000000

function initWatcherId() {
  BC_ID_ITTR += 1
  return BC_ID_ITTR
}

export function useWatcherId() {
  const rerender = useRerender()
  const watcherId = useInstance<number>(initWatcherId)

  // Bind our watcher to the event registry
  useEffect(() => {
    const trigger = EventRegistry.getTrigger(watcherId)
    EventRegistry.bind(trigger, rerender)
    return () => {
      EventRegistry.clearListeners(watcherId)
      EventRegistry.unbind(trigger, rerender)
    }
  }, [watcherId, rerender])

  return watcherId
}

export function useRenderWatcherId() {
  const watcherId = useWatcherId()
  // Clear any bound events from the registry every render as they will be set back up
  useEffect(() => {
    EventRegistry.clearListeners(watcherId)
  })

  return watcherId
}

export function useStoreBind(
  store: StoreProps,
  eventName: string,
  callback?: (event?: any) => void
) {
  const rerender = useRerender()
  useEffect(() => {
    if (eventName) {
      let tempCallback = callback
      if (!tempCallback) {
        tempCallback = rerender
      }
      store.bind(eventName, tempCallback)
      return () => {
        store.unbind(eventName, tempCallback)
      }
    }
  }, [store, eventName, callback, rerender])
}
