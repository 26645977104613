import { graphql, useSubscription } from 'relay-hooks'
import { useMemo } from 'react'

import logging from 'shared/utils/logging'
import useCurrentUser from 'web-client/hooks/useCurrentUser'
import { playSoundEffect } from 'web-client/utils/soundEffect'
import { removeNotification, sendNotification } from 'utilsLibrary/notificationUtils'
import type { Type as NotificationType } from 'design-system/components/Notifications/notificationUtils'
import { localize } from 'shared/utils/languageUtils'
import type { useNotificationsCreatedSubscription } from './__generated__/useNotificationsCreatedSubscription.graphql'
import type { useNotificationsRemovedSubscription } from './__generated__/useNotificationsRemovedSubscription.graphql'

const notificationCreatedSubscription = graphql`
  subscription useNotificationsCreatedSubscription {
    notificationCreated {
      template
      variables
      level
      playSoundEffect
      sticky
      timeout
    }
  }
`
const notificationRemovedSubscription = graphql`
  subscription useNotificationsRemovedSubscription {
    notificationRemoved {
      template
      variables
    }
  }
`

const handleError = (error: Error) => {
  logging.logError('Notification subscription error', error)
}

const handleCompleted = () => {
  logging.logInfo('Notification subscription closed by server', {})
}

const useNotifications = () => {
  const { isLoggedIn } = useCurrentUser()

  useSubscription<useNotificationsCreatedSubscription>(
    useMemo(
      () => ({
        subscription: notificationCreatedSubscription,
        variables: {},
        onError: handleError,
        onNext: (response) => {
          if (!response?.notificationCreated) {
            return
          }

          // In the current behavior, the notification will close automatically if it's not sticky and timeout is not set, timeout is first priority, continuing with the same logic
          const autoClose =
            response.notificationCreated.timeout ?? !response.notificationCreated.sticky

          sendNotification(
            localize(
              response.notificationCreated.template ?? undefined,
              response.notificationCreated.variables as Record<string, string>
            ),
            response.notificationCreated.level as NotificationType,
            {
              sticky: response.notificationCreated.sticky,
              timeout: response.notificationCreated.timeout,
              autoClose,
            }
          )

          if (response.notificationCreated.playSoundEffect) {
            playSoundEffect()
          }
        },
        onCompleted: handleCompleted,
      }),
      []
    ),
    { skip: !isLoggedIn }
  )

  useSubscription<useNotificationsRemovedSubscription>(
    useMemo(
      () => ({
        subscription: notificationRemovedSubscription,
        variables: {},
        onError: handleError,
        onNext: (response) => {
          if (!response?.notificationRemoved) {
            return
          }

          removeNotification(
            localize(
              response.notificationRemoved.template ?? undefined,
              response.notificationRemoved.variables as Record<string, string>
            )
          )
        },
        onCompleted: handleCompleted,
      }),
      []
    ),
    { skip: !isLoggedIn }
  )
}

export default useNotifications
