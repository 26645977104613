import React from 'react'
import styled from '@emotion/styled'
import colors from 'design-system/tokens/colors'

export type TimesSvgProps = {
  color?: string
  size?: number
}

export const TimesSvg = ({ color = colors.grays.l2, size = 16 }: TimesSvgProps) => (
  <svg height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z"
      fill={color}
    />
  </svg>
)

type Props = {
  color?: string
  disabled?: boolean
  size?: number
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const StyledButton = styled.button`
  background: transparent;
  border: none;
  box-shadow: none;
  box-sizing: border-box;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  display: block;
  line-height: 0;
  margin: 0;
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  outline: none;
  padding: 20px;
  position: absolute;
  right: 0;
  top: 0;
  user-select: none;
`

const CloseButton = ({ color, disabled, onClick, size, ...props }: Props) => (
  <StyledButton aria-label="Close" disabled={disabled} onClick={onClick} type="button" {...props}>
    <TimesSvg color={color} size={size} />
  </StyledButton>
)

export default CloseButton
