/* eslint-disable relay/unused-fields */

import { graphql, useFragment } from 'relay-hooks'
import useCurrentUser from 'web-client/hooks/useCurrentUser'
import { isEmpty } from 'lodash'
import type { useCompanyInfo_company$key } from './__generated__/useCompanyInfo_company.graphql'

const fragment = graphql`
  fragment useCompanyInfo_company on Company {
    logoUrl
    location {
      lat
      lng
    }
  }
`

const useCompanyInfo = () => {
  const { currentUser } = useCurrentUser()
  return useFragment<useCompanyInfo_company$key>(
    fragment,
    !isEmpty(currentUser.company) ? currentUser.company : null
  )
}

export default useCompanyInfo
