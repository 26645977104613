import { useEffect } from 'react'

import Consts from 'consts'
import useSplit from 'shared/hooks/useSplit'
import { useStoreBind } from 'stores/storeHooks'
import UserStore from 'stores/user_store.coffee'
import useScriptLoad from 'shared/hooks/useScriptLoad'
import useCurrentUser from './useCurrentUser'

export interface WalkMeWindow extends Window {
  _walkmeConfig: { smartLoad: boolean }
  _walkMe: unknown
  _walkme: {
    user: {
      id?: string
      name?: string | null
      type?: string
    }
  }
}

declare let window: WalkMeWindow

const SCRIPT_ID = 'walk_me_swoop_script'

const useWalkMe = () => {
  useStoreBind(UserStore, UserStore.USERME_CHANGED)
  const isWalkMeEnabled = useSplit(Consts.SPLITS.ENABLE_WALKME_INTEGRATION_TOOL)
  const { currentUser } = useCurrentUser()

  useEffect(() => {
    if (currentUser) {
      window._walkme = {
        user: {
          id: currentUser.id,
          name: currentUser.name,
          type: currentUser.company?.type,
        },
      }
    }
  }, [currentUser])
  const walkMeSrc = Consts.WALK_ME_FOR_SWOOP_SRC

  const skip = !isWalkMeEnabled || !walkMeSrc || window._walkMe

  const loaded = useScriptLoad({
    src: Consts.WALK_ME_FOR_SWOOP_SRC,
    scriptId: SCRIPT_ID,
    skip: !!skip,
  })

  useEffect(() => {
    if (loaded) {
      window._walkmeConfig = { smartLoad: true }
    }
  }, [loaded])
}

export default useWalkMe
