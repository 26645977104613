import React, { useMemo } from 'react'
import Consts from 'consts'
import { useEnvironmentVariable } from 'shared/utils/envUtils'
import styled from '@emotion/styled'
import colors from 'design-system/tokens/colors'
import fontSizes from 'design-system/tokens/fontSizes'
import lineHeights from 'design-system/tokens/lineHeights'
import fontWeights from 'design-system/tokens/fontWeights'

const FooterWrapper = styled.footer`
  margin: 0;
  padding: 0;
`

const FooterList = styled.ul`
  font-size: 0;
  padding: 0px;
  text-align: center;
  display: flex;
  align-self: center;
  justify-content: center;
  width: 100%;
  margin: 0px;
  gap: 10px;
`

const FooterItem = styled.li`
  display: inline-block;
  font-size: ${fontSizes.body};
  line-height: ${lineHeights.body};
  font-weight: ${fontWeights.normal};
  list-style: none;
  margin: 0px;
  padding: 0;
  color: ${colors.black};
`

const FooterPointElement = styled(FooterItem)`
  line-height: ${lineHeights.body};
  font-weight: ${fontWeights.semibold};
  margin: 0;
  color: ${colors.grays.l1};
`

const Link = styled(FooterPointElement)`
  color: ${colors.primary};
  margin: 0;
`.withComponent('a')

const EnterpriseFooter = ({ className }: { className?: string }) => {
  const [{ PRIVACY_URL, TERMS_URL }] = useEnvironmentVariable<{
    PRIVACY_URL: string
    TERMS_URL: string
  }>(useMemo(() => [Consts.ENV_VAR.PRIVACY_URL, Consts.ENV_VAR.TERMS_URL], []))

  return (
    <FooterWrapper className={className}>
      <FooterList>
        <FooterItem>©{new Date().getFullYear()} Agero, Inc.</FooterItem>
        <FooterPointElement>•</FooterPointElement>
        <FooterItem>
          <Link href={PRIVACY_URL} rel="noopener noreferrer" target="_blank">
            PRIVACY
          </Link>
        </FooterItem>
        <FooterPointElement>•</FooterPointElement>
        <FooterItem>
          <Link href="/provider_terms_conditions" rel="noopener noreferrer" target="_blank">
            SERVICE PROVIDER TERMS
          </Link>
        </FooterItem>
        <FooterPointElement>•</FooterPointElement>
        <FooterItem>
          <Link href={TERMS_URL} rel="noopener noreferrer" target="_blank">
            AGERO TERMS
          </Link>
        </FooterItem>
      </FooterList>
    </FooterWrapper>
  )
}

export default EnterpriseFooter
