import React, { useEffect } from 'react'
import styled from '@emotion/styled'

import 'web-client/utils/bridge'
import GlobalStyles from 'design-system/components/GlobalStyles'
import Header from 'web-client/components/Header'
import { usePrevious } from 'web-client/hooks/usePrevious'
import { useRoute, useRouter } from 'react-router5'
import userService from 'shared/utils/user'
import Footer from 'web-client/components/EnterpriseBorder/EnterpriseFooter'

type Props = {
  children: React.ReactNode
  hideFooter: boolean
  hideNavigation: boolean
  requireAuthentication?: boolean
}

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  min-height: 100vh;
  padding: 0;
`

const Main = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  margin: 0;
  order: 2;
  padding: 0;
`

const Layout = ({ children, hideFooter, requireAuthentication }: Props) => {
  const router5Context = useRoute()
  const prevRouteName = usePrevious(router5Context.route?.name ?? '')
  useEffect(() => {
    if (prevRouteName !== router5Context.route?.name) {
      // TODO: log the route change
    }
  }, [prevRouteName, router5Context.route])

  // TODO: Lift this code higher in the tree, to LazyLoader.js;
  // Why not now? Because this currently impacts only our limited number of "Greenfield"
  // routes. If we lift it, it will wrap the entire app, increasing risk.
  // We should save this task for another day; ticketed as ENG-25491
  const router = useRouter()
  useEffect(() => {
    if (requireAuthentication) {
      if (!userService.isAuthenticated()) {
        router.navigate('login', { returnUrl: window.location.pathname })
      }
    }
  })
  if (requireAuthentication && !userService.isAuthenticated()) {
    return null
  }

  return (
    <PageWrapper>
      <GlobalStyles />
      <Main>{children}</Main>
      {!hideFooter && <Footer />}
      <Header />
    </PageWrapper>
  )
}

export default Layout
