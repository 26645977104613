import styled from '@emotion/styled'
import fontWeights from 'design-system/tokens/fontWeights'
import spacing from 'design-system/tokens/spacing'
import DefaultButton from 'design-system/components/Button'

const Button = styled(DefaultButton)`
  font-weight: ${fontWeights.semibold};
  margin-left: ${spacing.xxs};

  &:first-of-type {
    margin-left: 0;
  }
`

export default Button
