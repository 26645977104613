import React from 'react'

import { RelayEnvironmentProvider } from 'relay-hooks'
import { RouterProvider } from 'react-router5'

import 'web-client/utils/rollbarConfigure'
import enforceSiteUrl from 'web-client/utils/enforceSiteUrl'
import relayService from 'shared/utils/relay'
import logging from 'shared/utils/logging'
import { SplitIOProvider } from 'shared/components/split.io'
import GlobalErrorBoundary from 'shared/components/ErrorBoundary/Global'
import setupFontAwesomeLibrary from 'design-system/tokens/fontAwesome'
import Context from 'web-client/router/Context'
import userService from 'shared/utils/user'
import EnvironmentIndicator from 'web-client/components/EnvironmentIndicator'
import { SwoopVersionProvider } from 'web-client/hooks/useSwoopVersion'
import LazyLoader from 'web-client/router/LazyLoaderSplit'
import renderToDOM from 'shared/applicationRenderer'
import routes from './routes'
import createRouter from './create-router'

enforceSiteUrl()

setupFontAwesomeLibrary()

userService.maintainSession(relayService.environment)

const router = createRouter(routes)

Context.setRouter5(router)

router.useMiddleware((_) => (toState, fromState, done) => {
  const route = routes.find(({ name }) => name === toState.name)

  if (route && 'beforeRender' in route) {
    if (typeof route.beforeRender !== 'function') {
      logging.logError("route's `beforeRender` should be a function", { route })
      done()
      return
    }

    void route.beforeRender(router, done)
    return
  }

  done()
})
router.start((error) => {
  if (error) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    logging.logError(error)
  }
  renderToDOM(
    <GlobalErrorBoundary>
      <RelayEnvironmentProvider environment={relayService.environment}>
        <EnvironmentIndicator />
        <RouterProvider router={router}>
          <SplitIOProvider>
            <SwoopVersionProvider>
              <LazyLoader />
            </SwoopVersionProvider>
          </SplitIOProvider>
        </RouterProvider>
      </RelayEnvironmentProvider>
    </GlobalErrorBoundary>
  )
})
