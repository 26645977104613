/**
 * @generated SignedSource<<acfd3a3df54062164b0e6addf246d0be>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useCompanyInfo_company$data = {
  readonly location: {
    readonly lat: number | null;
    readonly lng: number | null;
  } | null;
  readonly logoUrl: string | null;
  readonly " $fragmentType": "useCompanyInfo_company";
};
export type useCompanyInfo_company$key = {
  readonly " $data"?: useCompanyInfo_company$data;
  readonly " $fragmentSpreads": FragmentRefs<"useCompanyInfo_company">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useCompanyInfo_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "logoUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Location",
      "kind": "LinkedField",
      "name": "location",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lat",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lng",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Company",
  "abstractKey": null
};

(node as any).hash = "b213fba10505da9862cca4683ce9f013";

export default node;
