import { library } from '@fortawesome/fontawesome-svg-core'

// fal
import {
  faAngleLeft,
  faAngleRight,
  faBuilding,
  faCalendar as falCalendar,
  faCalendarAlt,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faEllipsisH,
  faExclamationCircle,
  faExternalLink,
  faInfoCircle as falInfoCircle,
  faListAlt,
  faPen,
  faRedoAlt,
  faSearch,
  faSquare,
  faStopwatch as falStopwatch,
  faTruck,
  faUndo,
} from '@fortawesome/pro-light-svg-icons'

// fas
import {
  faCalendar as fasCalendar,
  faCaretUp,
  faCaretDown,
  faCheckCircle,
  faCheckSquare,
  faCog,
  faCreditCard,
  faExclamationCircle as fasExclamationCircle,
  faExclamationTriangle,
  faInfoCircle,
  faPowerOff,
  faQuestionCircle,
  faStopwatch as fasStopwatch,
  faTimes,
} from '@fortawesome/pro-solid-svg-icons'

/**
 * Builds an application-wide library of FontAwesome icons
 *
 * ref: https://github.com/FortAwesome/react-fontawesome#build-a-library-to-reference-icons-throughout-your-app-more-conveniently
 */
const setupFontAwesomeLibrary = () =>
  library.add(
    faAngleLeft,
    faAngleRight,
    faBuilding,
    falCalendar,
    fasCalendar,
    faCalendarAlt,
    faCaretUp,
    faCaretDown,
    faCheck,
    faCheckCircle,
    faCheckSquare,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCog,
    faEllipsisH,
    faExclamationCircle,
    fasExclamationCircle,
    faExclamationTriangle,
    faExternalLink,
    faInfoCircle,
    faListAlt,
    faPen,
    faPowerOff,
    faQuestionCircle,
    faRedoAlt,
    faSearch,
    faSquare,
    falStopwatch,
    fasStopwatch,
    faTimes,
    faTruck,
    faUndo,
    falInfoCircle,
    faCreditCard
  )

export default setupFontAwesomeLibrary
