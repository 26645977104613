import React from 'react'
import styled from '@emotion/styled'
import Modal from 'design-system/components/Modals/components/Modal'
import useModal from 'design-system/components/Modals/hooks/useModal'
import spacing from 'design-system/tokens/spacing'

import Button from 'design-system/components/Modals/components/Button'
import Main from 'design-system/components/Modals/StandardModal/Main'
import { getErrorText } from 'design-system/components/ErrorContent'
import Header from '../components/Header'
import Footer from '../components/Footer'

type Props = {
  children?: React.ReactNode
  enqueuedToClose: boolean
  id: string
}

const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  height: auto;
  margin: 0 ${spacing.l} ${spacing.l};
  max-width: 100%;
  width: 580px;
`

const ErrorBoundaryModal = ({ ...props }: Props) => {
  const { closeModal } = useModal()

  const handleClose = () => {
    closeModal(props.id)
  }

  return (
    <Modal {...props}>
      <StyledSection>
        <Header>We’re sorry, a system error has occurred</Header>
        <Main>
          <p>{getErrorText()}</p>
        </Main>
        <Footer>
          <Button onClick={handleClose} size="m" type="button">
            Okay
          </Button>
        </Footer>
      </StyledSection>
    </Modal>
  )
}
export default ErrorBoundaryModal
