import { noop } from 'lodash'
import { createContext } from 'react'
import type { ModalItem } from './ModalProvider'

type ProviderMethods = {
  closeAllModals: () => void
  closeModal: (id?: number | string) => void
  modalIsOpen: (id: number | string) => boolean
  modalIsTop: (id: number | string) => boolean
  openModal: (modal: React.ReactElement, id?: string) => void
  modals: ModalItem[]
}

const ModalContext = createContext<ProviderMethods>({
  closeAllModals: noop,
  closeModal: noop,
  modalIsOpen: () => false,
  modalIsTop: () => false,
  openModal: noop,
  modals: [],
})

export default ModalContext
