import { useEffect } from 'react'

import datadog from 'shared/utils/logging/integrations/datadog'
import sessionService from 'web-client/utils/session'

/**
 * This is a place to put teardown functionality
 * such as when the user navigates away from the page.
 * Feel free to freely edit this file with additional functionality
 * that needs to happen on window unload,
 * or perhaps we find other events to include too.
 */

const useUnloadWindowFlow = () => {
  useEffect(() => {
    const handleBeforeUnload = () => {
      sessionService.set('datadogProps', JSON.stringify(datadog.props))
    }

    const clear = () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }

    const listen = () => {
      window.addEventListener('beforeunload', handleBeforeUnload)
    }

    listen()

    return clear
  }, [])
}

export default useUnloadWindowFlow
