import { useEffect } from 'react'

import datadog from 'shared/utils/logging/integrations/datadog'
import sessionService from 'web-client/utils/session'
import loggingInitializer from 'web-client/utils/logging-initializer'
import useNotifications from 'web-client/hooks/useNotifications'
import useSplitControls from 'web-client/hooks/useSplitControls'
import useCurrentUser from 'web-client/hooks/useCurrentUser'
import type { Context } from '@datadog/browser-core'
import useSessionTracker from './useSessionTracker'
import usePerformanceMetrics from './usePerformanceMetrics'

const fetchDatadogProps = () => {
  const fullStoryUrl =
    window.FS && window.FS.getCurrentSessionURL && window.FS.getCurrentSessionURL(true)
  const swoopVersion = window.VERSION
  return {
    fullStoryUrl,
    swoopVersion,
  }
}

/**
 * This is a place to put initialization stuff for
 * starting up the app. It should be edited with any new stuff to add
 * to the initialization process if desired.
 */

const useInitializeFlow = () => {
  const { currentUser, isLoggedIn } = useCurrentUser()

  useSessionTracker()
  usePerformanceMetrics()
  useSplitControls()

  useEffect(() => {
    const datadogProps: Context = JSON.parse(sessionService.get('datadogProps') || '{}')
    sessionService.remove('datadogProps')
    datadog.mergeProps(datadogProps)
    datadog.setCallback('useInitializeFlow', fetchDatadogProps)
  }, [])

  useNotifications()

  useEffect(() => {
    if (isLoggedIn && currentUser) {
      const company = { ...currentUser.company }

      // @ts-expect-error
      delete company.__fragmentOwner
      // @ts-expect-error
      delete company.__fragments

      loggingInitializer.initialize({
        id: currentUser.id,
        company,
      })
    } else {
      loggingInitializer.dispose()
    }

    return () => {
      loggingInitializer.dispose()
    }
  }, [currentUser])
}

export default useInitializeFlow
