import React, { useEffect, useState } from 'react'

import Consts from 'consts'
import LoadingSpinnerBase from 'design-system/components/LoadingSpinner'
import styled from '@emotion/styled'
import useSplit from 'shared/hooks/useSplit'

import LazyLoader from './LazyLoader'
import LazyLoaderOld from './LazyLoaderOld'

const LoadingSpinnerContainer = styled.div`
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`
const LoadingSpinner = styled(LoadingSpinnerBase)`
  width: 120px;
  height: 120px;
`

const LazyLoaderSplit = () => {
  const [isNewLazyLoaderEnabled, setIsNewLazyLoaderEnabled] = useState<boolean | 'loading'>(
    'loading'
  )
  const splitTreatment = useSplit<boolean | 'loading'>(
    Consts.SPLITS.ENABLE_APPLICATION_WRAPPER_MIGRATION,
    {},
    'loading'
  )

  useEffect(() => {
    if (isNewLazyLoaderEnabled === 'loading') {
      setIsNewLazyLoaderEnabled(splitTreatment)
    }
    // TODO: remove the modal context split
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [splitTreatment])

  useEffect(() => {
    let timeout: NodeJS.Timeout

    if (isNewLazyLoaderEnabled === 'loading') {
      timeout = setTimeout(() => setIsNewLazyLoaderEnabled(false), 5000)
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [isNewLazyLoaderEnabled])

  if (isNewLazyLoaderEnabled === 'loading') {
    return (
      <LoadingSpinnerContainer>
        <LoadingSpinner />
      </LoadingSpinnerContainer>
    )
  }

  if (!isNewLazyLoaderEnabled) {
    return <LazyLoaderOld />
  }

  return <LazyLoader />
}

export default LazyLoaderSplit
