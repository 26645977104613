import React from 'react'
import styled from '@emotion/styled'
import type { Variants } from 'framer-motion'
import { motion } from 'framer-motion'
import colors from 'design-system/tokens/colors'

type Props = {
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void | undefined
  variants?: Variants
}

const StyledDiv = styled(motion.div)`
  background-color: rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  bottom: 0;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;

  @media (prefers-reduced-motion) {
    opacity: 0.5 !important;
    background-color: ${colors.grays.l6};
  }
`

const Overlay = ({
  onClick,
  variants = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
    },
    exit: {
      opacity: 0,
    },
  },
}: Props) => (
  <StyledDiv
    animate="animate"
    data-testid="modal-overlay"
    exit="exit"
    initial="initial"
    onClick={onClick}
    variants={variants}
  />
)

export default Overlay
