import ding from 'sounds/ding_new.mp3'
import logging from 'shared/utils/logging'

export const registerSoundEffect = () => {
  window.soundEffect = new Audio(ding)
}

export const playSoundEffect = () => {
  const playSound = async () => {
    try {
      await window.soundEffect.play()
    } catch (error) {
      if (!(error instanceof Error)) {
        throw error
      }

      logging.logWarning('Cannot play sound', error)
    }
  }

  if (window.soundEffect) {
    void playSound()
  }
}
