import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { capitalize } from 'lodash'

import colors from 'design-system/tokens/colors'
import fontSizes from 'design-system/tokens/fontSizes'
import fontWeights from 'design-system/tokens/fontWeights'

const EnvTag = styled.div`
  background-color: ${colors.alerts.green};
  border-radius: 5px;
  color: #ffffff;
  font-size: ${fontSizes.bodySmall};
  font-weight: ${fontWeights.semibold};
  left: 5px;
  line-height: 2;
  margin: 0;
  padding: 0 24px;
  position: fixed;
  top: 50%;
  transform: rotate(-90deg) translate(-50%, 50%);
  transform-origin: center left;
  user-select: none;
  z-index: 1;
`

const EnvironmentIndicator = () => {
  const defaultDocumentTitle = 'Swoop'
  const showEnviromentIndicator = (enviroment: string) =>
    enviroment &&
    enviroment !== 'production' &&
    window.ENV?.DISABLE_ENVIRONMENT_INDICATOR !== 'true'

  useEffect(() => {
    if (window?.ENV.SWOOP_ENV) {
      if (showEnviromentIndicator(window.ENV.SWOOP_ENV)) {
        document.title = `${capitalize(window.ENV.SWOOP_ENV)} ${defaultDocumentTitle}`
      } else {
        document.title = defaultDocumentTitle
      }
    }
  }, [])

  if (!showEnviromentIndicator(window.ENV.SWOOP_ENV)) {
    return null
  }

  return <EnvTag>{window.ENV.SWOOP_ENV}</EnvTag>
}

export default EnvironmentIndicator
