import styled from '@emotion/styled'
import fontSizes from 'design-system/tokens/fontSizes'
import spacing from 'design-system/tokens/spacing'
import colors from 'design-system/tokens/colors'
import { Body } from 'design-system/components/Typography'

/**
 * @deprecated use FlexFooter instead
 */
const Footer = styled.footer`
  box-sizing: content-box;
  flex: none;
  font-size: ${fontSizes.h4};
  margin: ${spacing.l} 0 0;
  min-height: 1em;
  padding: 0;
  text-align: right;
`

export const FlexFooter = styled(Footer)`
  text-align: initial;
  display: flex;
  justify-content: flex-end;
`

export const Error = styled(Body.withComponent('div'))`
  margin-right: auto;
  align-self: center;
  color: ${colors.alerts.red};
`

export default Footer
