import type { Context } from '@datadog/browser-core'
import datadog from 'shared/utils/logging/integrations/datadog'
import { useEffect } from 'react'
import useCurrentUser from 'web-client/hooks/useCurrentUser'

const PREVIOUS_SESSION_KEY = 'RefreshSessionId'
let PREVIOUS_SESSION: string | null = null
let sessionStorageError = false
const sessionStorageErrorHandler = (e: unknown) => {
  sessionStorageError = true
  datadog.logInfo('sessionStorage error', e as Context)
}

try {
  PREVIOUS_SESSION = sessionStorage.getItem(PREVIOUS_SESSION_KEY)
} catch (e) {
  sessionStorageErrorHandler(e)
}

const useRefreshTracker = () => {
  const { isLoggedIn } = useCurrentUser()
  useEffect(() => {
    if (sessionStorageError) {
      return
    }
    if (isLoggedIn) {
      if (PREVIOUS_SESSION) {
        datadog.logInfo('User Refresh', {
          refresh_count: PREVIOUS_SESSION,
        })
      }
      const previousSessionParsed = PREVIOUS_SESSION && parseInt(PREVIOUS_SESSION, 10)
      const refreshCount = previousSessionParsed ? previousSessionParsed + 1 : 1
      try {
        sessionStorage.setItem(PREVIOUS_SESSION_KEY, `${refreshCount}`)
      } catch (e) {
        sessionStorageErrorHandler(e)
      }
    }
  }, [isLoggedIn])
  return null
}

export default useRefreshTracker
