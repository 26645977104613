import React from 'react'
import type { ReactElement, ReactNode } from 'react'

import styled from '@emotion/styled'
import { darken } from 'polished'

import colors from 'design-system/tokens/colors'
import { Body } from 'design-system/components/Typography'
import Button, { ButtonTextLink } from 'design-system/components/Button'
import fontWeights from 'design-system/tokens/fontWeights'
import { FlexFooter } from '../Footer'

const DeleteButton = styled(Button)`
  background: ${colors.alerts.red};
  margin-left: 16px;

  :hover {
    background: ${darken(0.1, colors.alerts.red)};
  }

  :active {
    background: ${darken(0.2, colors.alerts.red)};
  }
`

export const DeleteModalButton = styled(ButtonTextLink)`
  color: ${colors.alerts.red};
  font-weight: ${fontWeights.semibold};
  margin-right: 16px;
`

const ConfirmMessage = styled(Body)`
  margin-right: auto;
  align-self: center;
`.withComponent('div')

type ButtonsProps = {
  isSaveDisabled: boolean
  isDeleting: boolean
  onDeletingMode: () => void
  onDeleteConfirm: () => void
  onDeleteCancel: () => void
  customDeleteButton?: ReactElement
  isEditing?: boolean
  saveButtonText?: string
  children?: ReactNode
}

const Buttons = ({
  isDeleting,
  isEditing,
  isSaveDisabled,
  onDeleteCancel,
  onDeleteConfirm,
  onDeletingMode,
  customDeleteButton,
  saveButtonText = 'Save and Close',
  children,
}: ButtonsProps) => {
  if (isDeleting) {
    return (
      <>
        <ConfirmMessage>Are you sure you want to delete?</ConfirmMessage>
        <div>
          <Button onClick={onDeleteCancel} secondary type="button">
            Cancel
          </Button>
          <DeleteButton onClick={onDeleteConfirm} type="button">
            Delete
          </DeleteButton>
        </div>
      </>
    )
  }
  if (!children || isEditing) {
    return (
      <>
        {customDeleteButton || (
          <DeleteModalButton onClick={onDeletingMode} secondary type="button">
            Delete
          </DeleteModalButton>
        )}

        <Button disabled={isSaveDisabled}>{saveButtonText}</Button>
      </>
    )
  }
  return children
}

type EditFooterProps = ButtonsProps & {
  isLoading: boolean
}

const EditFooter = ({ isLoading, ...props }: EditFooterProps) => {
  return (
    <fieldset disabled={isLoading}>
      <FlexFooter>
        <Buttons {...props} />
      </FlexFooter>
    </fieldset>
  )
}

export default EditFooter
