import React from 'react'
import styled from '@emotion/styled'
import type { ModalProps } from 'design-system/components/Modals'
import { Modal } from 'design-system/components/Modals'
import spacing from 'design-system/tokens/spacing'

type Props = {
  twoColumns?: boolean
} & ModalProps

type StyleProps = {
  twoColumns?: boolean
}

export const defaultColumnWidth = 580
export const twoColumnWidth = 680

export const StyledSection = styled('section')<StyleProps>`
  display: flex;
  flex-direction: column;
  height: auto;
  margin: 0 ${spacing.l} ${spacing.l};
  max-width: 100%;
  width: ${({ twoColumns }) => {
    const width = twoColumns ? twoColumnWidth : defaultColumnWidth
    return `${width}px`
  }};

  @media screen and (max-width: 734px) {
    width: auto;
  }
`

const StandardModal = ({ children, twoColumns, ...props }: Props) => (
  <Modal {...props}>
    <StyledSection twoColumns={twoColumns}>{children}</StyledSection>
  </Modal>
)

export default StandardModal
