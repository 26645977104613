/**
 * @generated SignedSource<<96e7ff244db04d0690822f96b60bb359>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type CompanyType = "ClientCompany" | "PartnerCompany" | "SuperCompany";
export type loggingInitializerSubscription$variables = {
  id: string;
};
export type loggingInitializerSubscription$data = {
  readonly userUpdated: {
    readonly company: {
      readonly id: string;
      readonly name: string | null;
      readonly type: CompanyType;
    } | null;
    readonly id: string;
  };
};
export type loggingInitializerSubscription = {
  response: loggingInitializerSubscription$data;
  variables: loggingInitializerSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "userUpdated",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Company",
        "kind": "LinkedField",
        "name": "company",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "loggingInitializerSubscription",
    "selections": (v2/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "loggingInitializerSubscription",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "2bf5c77566ae12ffd0616a862ad9a55c",
    "id": null,
    "metadata": {},
    "name": "loggingInitializerSubscription",
    "operationKind": "subscription",
    "text": "subscription loggingInitializerSubscription(\n  $id: ID!\n) {\n  userUpdated(id: $id) {\n    id\n    company {\n      id\n      name\n      type\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "36b3e20b8d19d2fe25a092ce6d976bc4";

export default node;
