import useUnloadWindowFlow from './useUnloadWindowFlow'
import useInitializeFlow from './useInitializeFlow'

/**
 * This is a place to place setup and teardown
 * functionality for the app.
 */

const useConfiguration = () => {
  useInitializeFlow()
  useUnloadWindowFlow()
}

export default useConfiguration
