import styled from '@emotion/styled'

import fontSizes from 'design-system/tokens/fontSizes'
import fontWeights from 'design-system/tokens/fontWeights'
import lineHeights from 'design-system/tokens/lineHeights'

export const H3 = styled.h3`
  font-size: ${fontSizes.h3};
  font-weight: ${fontWeights.semibold};
  line-height: 30px;
  margin: 0;

  /* reset */
  color: inherit;
`
export const SH2 = styled.p`
  font-size: ${fontSizes.sh2};
  font-weight: ${fontWeights.semibold};
  line-height: ${lineHeights.sh2};
`
export const SH3 = styled.p`
  font-size: ${fontSizes.sh3};
  font-weight: ${fontWeights.normal};
  line-height: ${lineHeights.sh3};
`
export const BodyLarge = styled.p`
  font-size: ${fontSizes.bodyLarge};
  font-weight: ${fontWeights.normal};
  line-height: ${lineHeights.bodyLarge};
`
export const Body = styled.p`
  font-size: ${fontSizes.body};
  font-weight: ${fontWeights.normal};
  line-height: ${lineHeights.body};
`
export const BodySmall = styled.p`
  font-size: ${fontSizes.bodySmall};
  font-weight: ${fontWeights.semibold};
  line-height: ${lineHeights.bodySmall};
`

export const BodySmallNormal = styled.p`
  font-size: ${fontSizes.bodySmall};
  font-weight: ${fontWeights.normal};
  line-height: ${lineHeights.bodySmall};
`

export const Tiny = styled.p`
  font-size: ${fontSizes.tiny};
  font-weight: ${fontWeights.normal};
  line-height: ${lineHeights.tiny};
`
