/**
 * @generated SignedSource<<be7dd240f20000cac01646775f60fb32>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type useNotificationsCreatedSubscription$variables = {};
export type useNotificationsCreatedSubscription$data = {
  readonly notificationCreated: {
    readonly level: string | null;
    readonly playSoundEffect: boolean | null;
    readonly sticky: boolean | null;
    readonly template: string | null;
    readonly timeout: number | null;
    readonly variables: unknown | null;
  };
};
export type useNotificationsCreatedSubscription = {
  response: useNotificationsCreatedSubscription$data;
  variables: useNotificationsCreatedSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Notification",
    "kind": "LinkedField",
    "name": "notificationCreated",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "template",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "variables",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "level",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "playSoundEffect",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "sticky",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "timeout",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useNotificationsCreatedSubscription",
    "selections": (v0/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useNotificationsCreatedSubscription",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "6b163309f8b3f476174a8e1123d8a593",
    "id": null,
    "metadata": {},
    "name": "useNotificationsCreatedSubscription",
    "operationKind": "subscription",
    "text": "subscription useNotificationsCreatedSubscription {\n  notificationCreated {\n    template\n    variables\n    level\n    playSoundEffect\n    sticky\n    timeout\n  }\n}\n"
  }
};
})();

(node as any).hash = "336e7a9e36e5d586cff39f4c5719dede";

export default node;
