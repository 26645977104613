/**
 * @generated SignedSource<<151c8e06aeab89eb8bcc143f2ae54cbe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type useSwoopVersionUpdatedSubscription$variables = {};
export type useSwoopVersionUpdatedSubscription$data = {
  readonly versionUpdated: {
    readonly urgent: boolean;
    readonly version: string;
  };
};
export type useSwoopVersionUpdatedSubscription = {
  response: useSwoopVersionUpdatedSubscription$data;
  variables: useSwoopVersionUpdatedSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "VersionUpdate",
    "kind": "LinkedField",
    "name": "versionUpdated",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "version",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "urgent",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useSwoopVersionUpdatedSubscription",
    "selections": (v0/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useSwoopVersionUpdatedSubscription",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "ae2ac012dfdf076aa8d406f13a2204e4",
    "id": null,
    "metadata": {},
    "name": "useSwoopVersionUpdatedSubscription",
    "operationKind": "subscription",
    "text": "subscription useSwoopVersionUpdatedSubscription {\n  versionUpdated {\n    version\n    urgent\n  }\n}\n"
  }
};
})();

(node as any).hash = "9f941400fa7cc5d4b6cac621f8d80d13";

export default node;
