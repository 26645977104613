/**
 * @generated SignedSource<<0e5ed4cf7d39bc9dc53b248b7b3af311>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type useNotificationsRemovedSubscription$variables = {};
export type useNotificationsRemovedSubscription$data = {
  readonly notificationRemoved: {
    readonly template: string | null;
    readonly variables: unknown | null;
  };
};
export type useNotificationsRemovedSubscription = {
  response: useNotificationsRemovedSubscription$data;
  variables: useNotificationsRemovedSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Notification",
    "kind": "LinkedField",
    "name": "notificationRemoved",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "template",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "variables",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useNotificationsRemovedSubscription",
    "selections": (v0/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useNotificationsRemovedSubscription",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "dc83b32a294c613f88fa7084cc030b77",
    "id": null,
    "metadata": {},
    "name": "useNotificationsRemovedSubscription",
    "operationKind": "subscription",
    "text": "subscription useNotificationsRemovedSubscription {\n  notificationRemoved {\n    template\n    variables\n  }\n}\n"
  }
};
})();

(node as any).hash = "424af79ca7ca34896883cfdedf983ae8";

export default node;
