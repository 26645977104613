import React, { useEffect, useMemo, useState } from 'react'
import moment from 'moment-timezone'

import TimeContext from './TimeContext'
import MinuteContext from './MinuteContext'

type Props = {
  children?: React.ReactNode
}

const TimeProvider = ({ children }: Props) => {
  const [time, setTime] = useState(moment())
  const [secondsPassed, setSecondsPassed] = useState(0)

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(moment())
      setSecondsPassed((current) => current + 1)
    }, 1000)
    return () => clearInterval(timer)
  }, [])
  const minutesPassed = Math.floor(secondsPassed / 60)

  return (
    <MinuteContext.Provider value={minutesPassed}>
      <TimeContext.Provider
        value={useMemo(
          () => ({
            time,
          }),
          [time]
        )}
      >
        {children}
      </TimeContext.Provider>
    </MinuteContext.Provider>
  )
}

export default TimeProvider
