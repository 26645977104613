import createRouter from 'router5'
import browserPlugin from 'router5-plugin-browser'

export default function configureRouter(routes) {
  const router = createRouter(routes, {
    defaultRoute: 'home',
    queryParamsMode: 'loose',
  })

  router.usePlugin(browserPlugin())

  return router
}
