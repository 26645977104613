import { useEffect } from 'react'

import datadog from 'shared/utils/logging/integrations/datadog'

const useDatadogMemoryLogger = () => {
  useEffect(() => {
    const timer = setInterval(
      () => {
        try {
          const jsHeapSizeLimit = (window?.performance as any)?.memory?.jsHeapSizeLimit
          const totalJSHeapSize = (window?.performance as any)?.memory?.totalJSHeapSize
          const usedJSHeapSize = (window?.performance as any)?.memory?.usedJSHeapSize

          if (jsHeapSizeLimit && usedJSHeapSize) {
            datadog.logInfo('memory usage', {
              js_heap_size_limit: jsHeapSizeLimit,
              total_js_heap_size: totalJSHeapSize,
              used_js_heap_size: usedJSHeapSize,
              used_memory_percentage: (usedJSHeapSize * 100) / jsHeapSizeLimit,
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              memory_job_count: Object.keys(window.swoop?.store?.Job?.getAll()).length,
              job_update_count: window.swoop?.store?.Job?.resetAndReturnJobReceivedCount(),
            })
          }
        } catch (e) {
          datadog.logWarning('unable to detect memory', {})
        }
      },
      1000 * 60 * 5
    )

    return () => clearInterval(timer)
  }, [])
}

export default useDatadogMemoryLogger
