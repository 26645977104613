import React from 'react'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'

type Props = {
  alignment: 'left' | 'center' | 'right'
  children: React.ReactNode
}

export const MODAL_Z_INDEX = 9999

const StyledDiv = styled(motion.div)`
  backface-visibility: hidden;
  bottom: 0;
  box-sizing: border-box;
  left: 0;
  outline: none;
  overflow: hidden;
  overflow-y: auto;
  position: fixed;
  right: 0;
  text-align: ${({ alignment }: Props) => alignment};
  top: 0;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
  z-index: ${MODAL_Z_INDEX}; /* participating in the z-index wars; TODO: fix z-index app-wide #dreams */

  &::before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    width: 0;
  }
`

const Wrapper = ({ alignment, children, ...props }: Props) => (
  <StyledDiv
    alignment={alignment}
    animate={{ overflowY: 'auto' }} // manage scrollbar visibility during animation
    exit={{ overflowY: 'hidden' }}
    initial={{ overflowY: 'hidden' }}
    {...props}
  >
    {children}
  </StyledDiv>
)

export default Wrapper
